<template>
  <div class="login">
    <div class="d-flex container-fluid pa-0 flex-center">
      <!--begin::Signin-->
      <div class="login-page mx-auto">
        <div class="login-box">
          <div class="login-logo">
            <router-link :to="{ name: 'Store home' }"><b>TUMAR</b></router-link>
          </div>
          <div class="card border-0">
            <div class="card-body login-card-body">
              <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signin_form"
                @submit.stop.prevent="registerUser"
              >
                <p class="login-box-msg">Register a new membership</p>
                <div class="form-group">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Company name"
                      v-model="form.company_name"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <!-- <span class="fas fa-user"></span> -->
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First name"
                      v-model="form.first_name"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-user"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Last name"
                      v-model="form.last_name"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-user"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email"
                      v-model="form.email"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <select
                      class="form-control"
                      placeholder="Role"
                      v-model="form.role"
                    >
                      <option value="manager">Manager</option>
                      <option value="admin">Admin</option>
                    </select>
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      v-model="form.password"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Retype password"
                      v-model="form.password_confirmation"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                  <div class="row no-gutters">
                    <div class="col-8 px-0 d-flex align-items-center">
                      <div class="icheck-primary">
                        <input
                          type="checkbox"
                          id="agreeTerms"
                          name="terms"
                          ref="remember"
                          v-model="agreedToTerms"
                        />
                        <label for="agreeTerms">
                          I agree to the <a href="#">terms</a>
                        </label>
                      </div>
                    </div>
                    <div class="col-4">
                      <button
                        type="submit"
                        class="btn btn-primary font-weight-bolder"
                        style="width: 100%; font-size: 16px"
                        :disabled="!isValid"
                      >
                        Register
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <p class="mb-1">
                <v-list-item
                  :ripple="false"
                  link
                  :to="{ name: 'login' }"
                  class="px-0 font-weight-bolder"
                >
                  I already have a membership
                </v-list-item>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-list-item {
  min-height: unset;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #007bff;
  &:before {
    display: none;
  }
  &:hover {
    color: #0056b3;
  }
}
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
.form {
  button {
    color: white;
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const {
  mapActions: Actions_alerts,
  mapState: State_auth,
  mapGetters: Getters_auth,
} = createNamespacedHelpers('alerts')
const { mapActions: Actions_auth } = createNamespacedHelpers('auth')

export default {
  name: 'Register',
  data() {
    return {
      form: {
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        role: 'manager',
      },
      agreedToTerms: false,
    }
  },
  computed: {
    ...State_auth(['errors']),
    ...Getters_auth(['currentUser']),

    isValid() {
      let valids = []

      for (let i in this.form) {
        if (this.form[i]) {
          valids.push(true)
        }
      }

      return (
        valids.length === Object.keys(this.form).length && this.agreedToTerms
      )
    },
  },
  methods: {
    ...Actions_alerts(['addAlert']),
    ...Actions_auth(['register']),
    registerUser() {
      this.register(this.form)
        .then((res) => {})
        .catch((e) => {
          this.addAlert({
            text: e.response ? e.response.data.message : 'Что-то пошло не так',
            type: 'error',
          })
        })
    },
  },
}
</script>
